import { withTheme, styled } from '@material-ui/core/styles';
import { Form } from 'formik';

const CustomFormikForm = styled(Form)({
  '& .MuiOutlinedInput-root': {
    '&:not(.Mui-error)': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: props => props.theme.palette.divider,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
      borderWidth: '2px',
    },
  },
});

export default withTheme(CustomFormikForm);
