import { action } from 'easy-peasy';

import { DEFAULT_SNACKBAR } from '../../shared/utilities';

import { setLanguage } from 'react-multi-lang';

const globalModel = {
  update: {
    loading: true,
    update: false,
    version: '0.0.1',
  },
  language: ['en', 'es'].includes(localStorage.getItem('language')) ? localStorage.getItem('language') : 'es',
  theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
  title: '',
  snackbar: DEFAULT_SNACKBAR,
  setUpdate: action((state, payload) => {
    state.update = payload;
  }),
  setLanguage: action((state, payload) => {
    state.language = payload;
    localStorage.setItem('language', payload);
    setLanguage(payload);
  }),
  setTheme: action((state, payload) => {
    state.theme = payload;
    localStorage.setItem('theme', payload);
  }),
  storeTitle: action((state, payload) => {
    state.title = payload;
  }),
  setSnackbar: action((state, payload) => {
    state.snackbar = payload;
  }),
}

export default globalModel;
