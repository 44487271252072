import axios from 'axios';

import { logSentryEndpointError } from '../../shared/utilities';

const postContactUs = (actions, payload) => axios({
  method: 'post',
  url: process.env.REACT_APP_CONTACT_URL,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Content-Type': 'application/json'
  // },
  data: payload
})
.then(response => {
})
.catch(error => {
  actions.setError(error.response || true);
  logSentryEndpointError(error);
});

export default postContactUs;
