import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import Video from '../sections/Video';
import Features from '../sections/Features';
import Passenger from '../sections/Passenger';
import Driver from '../sections/Driver';
import Admin from '../sections/Admin';
import ContactUs from '../sections/ContactUs';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const Home = props => {
  // const classes = useStyles();

  return (
    <React.Fragment>
      <Video />
      <Features />
      <Passenger />
      <Container maxWidth="lg"><Divider /></Container>
      <Driver />
      <Container maxWidth="lg"><Divider /></Container>
      <Admin />
      <Container maxWidth="lg"><Divider /></Container>
      <ContactUs />
    </React.Fragment>
  );
}

export default Home;
