import { createStore } from 'easy-peasy';

import globalModel from './models/global';
import contactUsModel from './models/contactUs';

const storeModel = {
  global: globalModel,
  contactUs: contactUsModel,
};

const store = createStore(storeModel);

export default store;
