import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Fade from 'react-reveal/Fade';

import lightSvg from '../assets/light-passenger.svg';

import Headline from '../components/Headline';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(8, 0, 8),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10, 0, 10),
    },
    '& .MuiGrid-container': {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    '& .MuiTypography-body1': {
      whiteSpace: 'pre-line',
    },
  },
  headline: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6),
    },
  },
  image: {
    textAlign: 'center',
    '& img': {
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '40vh',
        maxHeight: '40vh'
      },
    },
  },
}));

const Passenger = props => {
  const classes = useStyles();

  const t = useTranslation();

  return (
    <div id="passenger" className={ classes.root }>
      <Container maxWidth="md">
        <Grid container spacing={ 4 }>
          <Grid className={ classes.image } item xs={ 12 } md={ 4 }>
            <Fade left>
              <img src={ lightSvg } alt="" />
            </Fade>
          </Grid>
          <Grid item xs={ 12 } md={ 8 }>
            <Headline className={ classes.headline } text={ t('menu.passenger') } topLine />
            <Typography>{ t('passenger.description') }</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Passenger;
