import { action, thunk } from 'easy-peasy';

import postContactUs from '../../services/contactUs/post';

const contactUsModel = {
  error: '',
  postContactUs: thunk(async (actions, payload) => {
    actions.setError('');
    return await postContactUs(actions, payload);
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
}

export default contactUsModel;
