import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import ReCAPTCHA from 'react-google-recaptcha';

import Headline from '../components/Headline';
import CustomFormikForm from '../components/CustomFormikForm';

import { handleEndpointErrors } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(8, 0, 8),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10, 0, 10),
    },
  },
  actions: {
    marginTop: theme.spacing(1),
  },
  reCaptchaError: {
    color: theme.palette.error.main,
    paddingLeft: '14px',
  },
  submit: {
    textAlign: 'right',
  },
}));

const ContactUs = props => {
  const classes = useStyles();
  const [reCaptchaError, setReCaptchaError] = React.useState(false);

  const store = useStore();

  const globalLanguage = useStoreState(state => state.global.language);
  const globalTheme = useStoreState(state => state.global.theme);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const postContactUs = useStoreActions(actions => actions.contactUs.postContactUs);

  const reCaptchaRef = React.createRef();

  const onReCaptchaChange = value => {
    value ? setReCaptchaError(false) : setReCaptchaError(true);
  };

  const t = useTranslation();

  return (
    <div id="contact-us" className={ classes.root }>
      <Container maxWidth="md">
        <Headline text={ t('menu.contactUs') } topLine />
        <Formik
          initialValues={{ name: '', email: '', message: '' }}
          validationSchema={ Yup.object({
            name: Yup.string()
              .min(3, t('global.errors.min', { field: t('contactUs.name'), length: 3 }))
              .max(40, t('global.errors.max', { field: t('contactUs.name'), length: 40 }))
              .required(t('global.errors.required', { field: t('contactUs.name') })),
            email: Yup.string()
              .email(t('global.errors.email'))
              .max(100, t('global.errors.max', { field: t('contactUs.name'), length: 100 }))
              .required(t('global.errors.required', { field: t('contactUs.email') })),
            message: Yup.string()
              .min(5, t('global.errors.min', { field: t('contactUs.name'), length: 5 }))
              .max(1000, t('global.errors.max', { field: t('contactUs.name'), length: 1000 }))
              .required(t('global.errors.required', { field: t('contactUs.message') })),
          }) }
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const recaptchaValue = reCaptchaRef.current.getValue();

            if ( recaptchaValue ) {
              setReCaptchaError(false);
              postContactUs({ name: values.name, email: values.email, content: values.message, recaptcha: recaptchaValue }).then(() => {
                const contactUsState = store.getState().contactUs;
                if (!contactUsState.error) {
                  // console.log(contactUsState);
                  resetForm();
                  setSnackbar({ show: true, autoHideDuration: 2000, severity: 'success', message: t('global.success.emailSent') });
                } else {
                  handleEndpointErrors(contactUsState, props, setSnackbar, t);
                }
                setSubmitting(false);
              });
            } else {
              setReCaptchaError(true);
              setSubmitting(false);
            }
          }
        }
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } sm={ 6 }>
                <Field
                  component={ TextField }
                  name="name"
                  type="text"
                  label={ t('contactUs.name') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={ 12 } sm={ 6 }>
                <Field
                  component={ TextField }
                  name="email"
                  type="text"
                  label={ t('contactUs.email') }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={ 12 }>
                <Field
                  component={ TextField }
                  name="message"
                  type="text"
                  label={ t('contactUs.message') }
                  variant="outlined"
                  multiline
                  rows={ 4 }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid className={ classes.actions } container alignItems="center" spacing={ 2 }>
              <Grid item xs={ 12 } sm={ 6 }>
                <ReCAPTCHA
                  ref={ reCaptchaRef }
                  sitekey={ process.env.REACT_APP_RE_CAPTCHA }
                  theme={ globalTheme === 'dark' ? 'dark' : 'light' }
                  hl={ globalLanguage }
                  onChange={ onReCaptchaChange }
                />
                { reCaptchaError && <Typography className={ classes.reCaptchaError } variant="caption" display="block">{ t('contactUs.reCaptchaError') }</Typography> }
              </Grid>
              <Grid className={ classes.submit } item xs={ 12 } sm={ 6 }>
                <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('contactUs.submit') }</Button>
              </Grid>
            </Grid>
          </CustomFormikForm>
        )}
      </Formik>
      </Container>
    </div>
  );
}

export default ContactUs;
