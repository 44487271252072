import * as Sentry from '@sentry/browser';

/**
 * Scroll to a specific section/place.
 * @param event - Click event.
 * @param {string} target - ID or class of the section/place that will be scrolled to.
 */
export const scrollTo = (event, target) => {
  const anchor = (event.target.ownerDocument || document).querySelector(target);
  
  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth' });
  }
};

export const DEFAULT_SNACKBAR = { show: false, autoHideDuration: 2000, severity: 'success', message: '' };

/**
 * Handle endpoint errors
 * @param {Object} state - The current state that you want to check.
 * @param {Object} props - The props of the page.
 * @param {function} setSnackbar - The action to call the snackbar component.
 * @param {function} t - The translation function.
 * @param {string} entity - Optional: The entity of the current action to be used on some messages
 */
export const handleEndpointErrors = (state, props, setSnackbar, t, entity) => {
  // if (state.error.status === 401) {
  //   props.logout(true, true);
  // } else  if (state.error.status === 420) {
  //   if (entity) {
  //     setSnackbar({ show: true, severity: 'error', message: t('global.errors.endpoint.420withEntity', { entity }) });
  //   } else {
  //     setSnackbar({ show: true, severity: 'error', message: t('global.errors.endpoint.420withoutEntity') });
  //   }
  // } else {
  //   setSnackbar({ show: true, severity: 'error', message: t('global.errors.endpoint.default') });
  // }
  setSnackbar({ show: true, severity: 'error', message: t('global.errors.endpoint') });
}

/**
 * Log Sentry endpoint error
 * @param {Object} error - Error that occurred.
 */
export const logSentryEndpointError = error => {
  if (error.response && error.response.status !== 401) {
    Sentry.withScope(function(scope) {
      scope.setTag('request', `${ error.response.config.url }`);
      scope.setTag('method', `${ error.config.method }`);
      Sentry.setContext('Request Information', {
        'URL': error.response.config.url,
        'Method': error.config.method,
        'Status': error.request.status,
        'Status Text': error.request.statusText,
        'Data': error.config.data,
        'Error': error.request.response,
      });
      Sentry.captureException(error);
    });
  }
}
