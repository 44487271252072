import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions } from 'easy-peasy';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Brightness4Icon from '@material-ui/icons/Brightness4';
// import Brightness7Icon from '@material-ui/icons/Brightness7';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SettingsIcon from '@material-ui/icons/Settings';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';

import Fade from 'react-reveal/Fade';

import Scrollspy from 'react-scrollspy';

import ReactCountryFlag from 'react-country-flag';

import { scrollTo } from '../shared/utilities';

import Logo from './Logo';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 1,
    '& .MuiAppBar-root': {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${ theme.palette.divider }`,
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiContainer-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  logoBtn: {
    borderRadius: 0,
  },
  drawer: {
    '& .MuiPaper-root': {
      borderBottomRightRadius: '40px',
      borderTopRightRadius: '40px',
    },
  },
  drawerItems: {
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  navItems: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  navActiveItem: {
    color: theme.palette.text.primary,
  },
  list: {
    width: 300,
  },
  scrollTop: {
    bottom: theme.spacing(2),
    position: 'fixed',
    right: theme.spacing(2),
  },
  settingsMenu: {
    zIndex: '1301 !important',
    '& .MuiMenuItem-root': {
      color: theme.palette.text.secondary,
    },
    '& img': {
      marginRight: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
      height: '16px',
      marginRight: theme.spacing(1),
      width: '16px',
    },
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={ false } direction="down" in={ !trigger }>
      { children }
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Zoom in={ trigger }>
      <div onClick={ event => scrollTo(event, '#back-to-top-anchor') } role="presentation" className={ classes.scrollTop }>
        { children }
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};

const AppMenu = props => {
  const { theme } = props;
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const globalLanguage = useStoreState(state => state.global.language);
  const setGlobalLanguage = useStoreActions(actions => actions.global.setLanguage);

  // const globalTheme = useStoreState(state => state.global.theme);
  // const setGlobalTheme = useStoreActions(actions => actions.global.setTheme);

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenuOpen(open);
  };

  const onDrawerItemClick = (event, anchor) => {
    event.stopPropagation();
    scrollTo(event, anchor);
    setTimeout(() => setMenuOpen(false), 800);
  };

  const handleMenuClick = event => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuLanguageChange = language => {
    setGlobalLanguage(language);
    handleMenuClose();
  };

  // const handleMenuThemeChange = theme => {
  //   setGlobalTheme(theme);
  //   handleMenuClose();
  // };

  const t = useTranslation();

  const list = (
    <div
      className={ classes.list }
      role="presentation"
      onClick={ toggleDrawer(false) }
      onKeyDown={ toggleDrawer(false) }
    >
      <List className={ classes.drawerItems }>
        <ListItem button onClick={ event => onDrawerItemClick(event, '#back-to-top-anchor') } >
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary={ t('menu.home') } />
        </ListItem>
        <ListItem button onClick={ event => onDrawerItemClick(event, '#features') }>
          <ListItemIcon><PlaylistAddCheckIcon /></ListItemIcon>
          <ListItemText primary={ t('menu.features') } />
        </ListItem>
        <ListItem button onClick={ event => onDrawerItemClick(event, '#passenger') }>
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <ListItemText primary={ t('menu.passenger') } />
        </ListItem>
        <ListItem button onClick={ event => onDrawerItemClick(event, '#driver') }>
          <ListItemIcon><LocalTaxiIcon /></ListItemIcon>
          <ListItemText primary={ t('menu.driver') } />
        </ListItem>
        <ListItem button onClick={ event => onDrawerItemClick(event, '#admin') }>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={ t('menu.admin') } />
        </ListItem>
        <ListItem button onClick={ event => onDrawerItemClick(event, '#contact-us') }>
          <ListItemIcon><PermContactCalendarIcon /></ListItemIcon>
          <ListItemText primary={ t('menu.contactUs') } />
        </ListItem>
        <ListItem button onClick={ handleMenuClick }>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary={ t('menu.settings.label') } />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={ classes.root }>
      <HideOnScroll { ...props }>
        <AppBar>
          <Container maxWidth="lg">
            <Toolbar>
              <Hidden mdUp>
                <IconButton onClick={ toggleDrawer(true) } edge="start" className={ classes.menuButton } color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Fade>
                <IconButton className={ classes.logoBtn } onClick={ event => scrollTo(event, '#back-to-top-anchor') }>
                  <Logo color={ theme.palette.background.illustration } />
                </IconButton>
              </Fade>
              <Hidden smDown>
                <Fade>
                  <div className={ classes.navItems }>
                    <Scrollspy items={ ['video', 'features', 'passenger', 'driver', 'admin', 'contact-us'] } currentClassName={ classes.navActiveItem } offset={ -200 }>
                      <Button onClick={ event => scrollTo(event, '#back-to-top-anchor') } color="inherit">{ t('menu.home') }</Button>
                      <Button onClick={ event => scrollTo(event, '#features') } color="inherit">{ t('menu.features') }</Button>
                      <Button onClick={ event => scrollTo(event, '#passenger') } color="inherit">{ t('menu.passenger') }</Button>
                      <Button onClick={ event => scrollTo(event, '#driver') } color="inherit">{ t('menu.driver') }</Button>
                      <Button onClick={ event => scrollTo(event, '#admin') } color="inherit">{ t('menu.admin') }</Button>
                      <Button onClick={ event => scrollTo(event, '#contact-us') } color="inherit">{ t('menu.contactUs') }</Button>
                      <IconButton onClick={ handleMenuClick } aria-label={ t('menu.settings.label') }><SettingsIcon /></IconButton>
                    </Scrollspy>
                  </div>
                </Fade>
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Hidden mdUp>
        <Drawer className={ classes.drawer } anchor="left" open={ menuOpen } onClose={ toggleDrawer(false) }>
          { list }
        </Drawer>
      </Hidden>
      <Toolbar id="back-to-top-anchor" />
      <ScrollTop { ...props }>
        <Fab color="secondary" size="small" aria-label={ t('global.scrollTop') }>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Menu
        className={ classes.settingsMenu }
        anchorEl={ menuAnchorEl }
        getContentAnchorEl={ null }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={ Boolean(menuAnchorEl) }
        onClose={ handleMenuClose }
      >
        <MenuItem onClick={ () => handleMenuLanguageChange('en') } selected={'en' === globalLanguage}>
          <ReactCountryFlag countryCode="US" svg />
          { t('menu.settings.language.english') }
        </MenuItem>
        <MenuItem onClick={ () => handleMenuLanguageChange('es') } selected={'es' === globalLanguage}>
          <ReactCountryFlag countryCode="CR" svg />
          { t('menu.settings.language.spanish') }
        </MenuItem>
        {/* <MenuItem onClick={ () => handleMenuThemeChange('light') } selected={'light' === globalTheme}>
          <Brightness4Icon />
          { t('menu.settings.theme.light') }
        </MenuItem>
        <MenuItem onClick={ () => handleMenuThemeChange('dark') } selected={'dark' === globalTheme}>
          <Brightness7Icon /> 
          { t('menu.settings.theme.dark') }
        </MenuItem> */}
      </Menu>
    </div>
  );
}

export default withTheme(AppMenu);
