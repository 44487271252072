import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import Container from '@material-ui/core/Container';
import CropFreeOutlinedIcon from '@material-ui/icons/CropFreeOutlined';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import Fade from 'react-reveal/Fade';

import lightSvg from '../assets/light-features.svg';

import Headline from '../components/Headline';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
    },
    '& .MuiList-root': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      [theme.breakpoints.up('md')]: {
        columnCount: 2,
      },
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  image: {
    height: 'auto',
    width: '100%',
  },
}));

const Features = props => {
  const classes = useStyles();

  const t = useTranslation();

  return (
    <div id="features" className={ classes.root }>
      <Container maxWidth="md">
        <Headline text={ t('menu.features') } topLine />
        <List>
          { [
              <CardTravelIcon />,
              <LocalActivityIcon />,
              <TrackChangesIcon />,
              <AssessmentOutlinedIcon />,
              <CropFreeOutlinedIcon />,
              <LocalOfferIcon />,
              <DeveloperBoardIcon />,
            ].map((icon, index) => <Fade bottom cascade key={ index }>
              <ListItem>
                <ListItemIcon>
                  { icon }
                </ListItemIcon>
                <ListItemText primary={ JSON.parse( t('features') )[index] } />
              </ListItem>
            </Fade>
            )
          }
        </List>
      </Container>
      <img className={ classes.image } src={ lightSvg } alt="" />
    </div>
  );
}

export default Features;
