import React from 'react';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Logo from './Logo';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0),
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  description: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      display: 'flex',
      '& .MuiTypography-root::before': {
        backgroundColor: theme.palette.text.primary,
        content: '""',
        display: 'inline-block',
        height: '24px',
        margin: '0 20px',
        verticalAlign:' middle',
        width: '1px',
      },
    },
  },
  copyright: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
}));

const Footer = props => {
  const { theme } = props;
  const classes = useStyles();

  const t = useTranslation();

  return (
    <div className={ classes.root }>
      <Container maxWidth="lg">
        <Grid container spacing={ 2 } justify="center" alignItems="center">
          <Grid className={ classes.description } item xs={ 12 } md={ 9 }>
            <Logo color={ theme.palette.primary.dark } /> 
            <Typography variant="body1">{ t('footer.description') }</Typography>
          </Grid>
          <Grid className={ classes.copyright } item xs={ 12 } md={ 3 }>
            <Typography variant="body1">© { new Date().getFullYear() } { t('footer.copyright') }</Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withTheme(Footer);
