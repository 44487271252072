import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import lightSvg from '../assets/light-home.svg';

import Headline from '../components/Headline';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    textAlign: 'left',
    '& .MuiButton-root': {
      borderRadius: '100px',
      padding: theme.spacing(2, 4)
    },
    '& .MuiTypography-body1': {
      whiteSpace: 'pre-line',
    },
  },
  video: {
    border: `1px solid ${ theme.palette.primary.light }`,
    borderRadius: '4px',
    display: 'block',
    overflow: 'hidden',
    padding: 0,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
      maxWidth: 'calc(100% - 155px)',
    },
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: '56.25%',
    },
    '& iframe': {
      border: 0,
      bottom: 0,
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
  image: {
    height: 'auto',
    marginTop: '-12vw',
    width: '100%',
  },
}));

const Video = props => {
  const classes = useStyles();

  const t = useTranslation();

  return (
    <div id="video" className={ classes.root }>
      <Container maxWidth="lg">
        <div className={ classes.video }>
          <iframe title="MoveOn" src="https://www.youtube.com/embed/fJd3euwGN1I?rel=0" modestbranding="1" autohide="1" showinfo="0" controls="0" frameBorder="0" allowFullScreen></iframe>
        </div>
      </Container>
      <img className={ classes.image } src={ lightSvg } alt="" />
      <Container maxWidth="lg">
        <Headline text={ t('video.title') } />
        <Typography>{ t('video.description') }</Typography>
      </Container>
    </div>
  );
}

export default Video;
