import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '& .MuiTypography-h3': {
      color: theme.palette.primary.dark,
      fontSize: '30px',
      fontWeight: 900,
      lineHeight: '30px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('md')]: {
        fontSize: '48px',
        lineHeight: '48px',
      },
    },
  },
  topLine: {
    '&::before': {
      backgroundColor: theme.palette.primary.main,
      content: '""',
      display: 'block',
      height: '4px',
      left: 0,
      position: 'absolute',
      top: theme.spacing(-1),
      width: '60px',
      [theme.breakpoints.up('md')]: {
        height: '8px',
        top: theme.spacing(-2),
        width: '120px',
      },
    },
  },
}));

const Headline = props => {
  const { text, topLine, className } = props;
  const classes = useStyles();

  return (
    <div className={ clsx(classes.root, {
      [classes.topLine]: topLine,
      [className]: className,
    }) }>
      <Typography variant="h3" component="h2" gutterBottom>{ text }</Typography>
    </div>
  );
}

Headline.propTypes = {
  text: PropTypes.string.isRequired,
  topLine: PropTypes.bool,
  className: PropTypes.string,
};

export default Headline;
